import { Auth0VueClient } from "@auth0/auth0-vue";

export const useLogout = async (auth0Client: Auth0VueClient) => {
  // log the auth0 client out
  // NOTE: Ignore the TypeScript error, this is correct. It's possible the type is wrong.
  try {
    await auth0Client.logout({
      returnTo: `${window.location.origin}/logout`,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  // clear any browser storage
  localStorage.removeItem("localKeywords");
  localStorage.removeItem("localBooleanData");
  localStorage.removeItem("user");
  localStorage.removeItem("currentSearch");
  localStorage.removeItem("currentComparisonSet");
  localStorage.removeItem("organization");
  localStorage.removeItem("sessionDateRange");
  document.cookie = "force-refresh-session=true";
  sessionStorage.clear();
};
